import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { UpdateService } from './update.service';

import defaultLanguage from '../../assets/i18n/en.json';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    loaded    = new BehaviorSubject(false);
    previousLanguage = null;
    language  = new BehaviorSubject(null);
    storedLanguage = new BehaviorSubject(null);
    languages = new BehaviorSubject([]);

    constructor(
        private dataService: DataService,
        private updateService: UpdateService,
        private apiService: ApiService,
        private translate: TranslateService
    ) {
        this.translate.setTranslation('en-local', defaultLanguage);
        this.translate.setDefaultLang('en-local');
    }

    async init() {
        console.log('Loading Language');

        this.dataService.get('languages').subscribe(languages => {
            if (this.languages.value !== languages) {
                this.languages.next(languages);
            }

            if (languages && languages.length && ! this.language.value) {
                this.setDefaultLang();
            }
        });

        this.dataService.get('language').subscribe(language => {
            if (language) {
                if (this.language.value !== language) {
                    this.language.next(language);
                }
                this.storedLanguage.next(language);
            }

            if (this.languages.value && this.languages.value.length && ! language) {
                this.setDefaultLang();
            }
        });

        this.getLanguage().subscribe(language => {
            if (language) {
                console.log('Changing language to ' + language);
                this.setTranslation(language);
            } else {
                console.log('Unsetting language');
            }
            this.translate.use(language);
        });

        this.loaded.next(true);
    }

    setDefaultLang() {
        let defaultLang = null;
        const lang = this.getBrowserLang();
        const shortLang = lang.substr(0, 2);

        for (const index in this.languages.value) {
            const item = this.languages.value[index];
            if ((!defaultLang || defaultLang.length < item.key.length) && (item.key === lang || item.key === shortLang)) {
                defaultLang = item.key;
            }
        }

        if (defaultLang) {
            this.setLanguage(defaultLang, false);
        }
    }

    getBrowserLang() {
        return (navigator.languages ? navigator.languages[0] : (navigator.language || 'en-gb')).toLowerCase();
    }

    getLanguages() {
        return this.languages;
    }

    getLanguage() {
        return this.language;
    }

    getStoredLanguage() {
        return this.storedLanguage;
    }

    setTranslation(lang: string) {
        const translation = this.dataService.get('language/' + lang).value || null;
        if (translation) {
            this.translate.setTranslation(lang, translation);
        }

        this.apiService.language(lang)
            .subscribe(async language => {
                await this.dataService.set('language/' + language.key, language.json);
                this.translate.setTranslation(language.key, language.json);
            });
    }

    setLanguage(lang: string, permanent: boolean = true) {
        return new Promise(async resolve => {
            if (permanent) {
                await this.dataService.set('language', lang);

                await this.dataService.clearCache();
                await this.updateService.update();
            } else {
                this.language.next(lang);
            }

            resolve();
        });
    }
}
