export const environment = {
  production: true
};

export const VERSION = '3.1.11';

export const API_HOST = 'https://staging.api.driveiq.schk.co/api';

export const CDN_HOST = 'https://staging.api.driveiq.schk.co';

export const SENTRY_DSN = 'https://4991e03c2acd48299e2476ab58d05e61@sentry.io/1763072';
