import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { DataService } from './data.service';
import { ApiService } from './api.service';
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from 'sentry-cordova';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    constructor(
        private apiService: ApiService,
        private dataService: DataService,
        private navController: NavController,
        private alertController: AlertController,
        private translate: TranslateService
    ) {
    }

    isAuthenticated() {
        return !!this.dataService.get('auth/loggedin').value;
    }

    getUser() {
        return this.dataService.get('auth/user').value;
    }

    getFlow() {
        return String(this.dataService.get('auth/flow').value);
    }

    async processMe(me) {
        const user = {
            firstname: me.firstname,
            lastname: me.lastname,
            email: me.email,
            language: me.language,
            confirmed: me.confirmed,
            introed: me.introed,
            flow_id: Object.keys(me.flows).filter(k => me.flows[k])[0],
            flows: me.flows,
            score: me.score,
            date: (new Date()).getTime(),
            completed_at: me.completed_at,
            agree_etiquette: me.agree_etiquette,
            goals: me.goals,
            current_period: me.current_period,
            compliant: me.compliant,
            engagement: me.engagement,
            company_logo: me.company_logo,
            company_name: me.company_name,
            next_flow: me.next_flow,
            is_sso: me.is_sso,
            deactivated: me.deactivated,
        };

        let flowId = this.getFlow();
        if (flowId && Object.keys(user.flows).indexOf(String(flowId)) === -1) {
            flowId = null;
        }
        if (!flowId) {
            flowId = Object.keys(user.flows).filter(k => user.flows[k])[0];
        }
        user.flow_id = flowId;

        let promises = [];
        promises.push(this.dataService.set('language', me.language));
        promises.push(this.dataService.set('lastuser/email', me.email));

        promises.push(this.dataService.set('auth/loggedin', true));
        promises.push(this.dataService.set('auth/user', user));
        promises.push(this.dataService.set('auth/flow', user.flow_id));
        promises.push(this.dataService.set('auth/date', user.date));

        await Promise.all(promises);

        if (window && window.localStorage) {
            window.localStorage.setItem('error/email', me.email);
        }

        Sentry.configureScope(scope => {
            scope.setUser({
                email: me.email,
            });
        });

        return user;
    }

    check(): Observable<any> {
        return new Observable(observer => {
            this.apiService.me()
            .subscribe(
                async res => {
                    const user = await this.processMe(res);

                    observer.next(user);
                    observer.complete();
                },
                async err => {
                    if (err.status === 401) {
                        const alert = await this.alertController.create({
                            header: await this.translate.instant('auth.logout.header'),
                            message: await this.translate.instant('auth.logout.text'),
                            buttons: [await this.translate.instant('auth.logout.submit')],
                            mode: 'ios',
                        });

                        await alert.present();

                        await this.logout();
                        observer.next(null);
                        observer.complete();
                        return;
                    }

                    observer.next(this.getUser());
                    observer.complete();
                }
            );
        }).pipe(take(1));
    }

    changeDetails(
        firstname: string,
        lastname: string,
        email: string
    ): Observable<any> {
        return new Observable(observer => {
            this.apiService.changeDetails(
                firstname,
                lastname,
                email
            )
            .subscribe(
                async res => {
                    const user = await this.processMe(res);

                    observer.next(user);
                    observer.complete();
                },
                err => {
                    if (err.status === 401) {
                        this.logout();
                    }

                    observer.error(err);
                }
            );
        }).pipe(take(1));
    }

    changePassword(
        password: string
    ): Observable<any> {
        return new Observable(observer => {
            this.apiService.changePassword(
                password
            )
            .subscribe(
                async res => {
                    const user = await this.processMe(res);

                    observer.next(user);
                    observer.complete();
                },
                err => {
                    if (err.status === 401) {
                        this.logout();
                    }

                    observer.error(err);
                }
            );
        }).pipe(take(1));
    }

    getToken() {
        switch (this.dataService.get('auth/type').value) {
            case "Bearer":
                return 'Bearer ' + this.dataService.get('auth/token').value;
        }

        return null;
    }

    async setToken(type, token = null) {
        let promises = [];

        promises.push(this.dataService.set('auth/type', type));
        promises.push(this.dataService.set('auth/token', token));

        await Promise.all(promises);
    }

    async clearSession() {
        const promises = [];

        promises.push(this.setToken(null));
        promises.push(this.dataService.clearSession());

        await Promise.all(promises);
    }

    async logout() {
        await this.clearSession();

        this.navController.navigateRoot(['login']);
    }

    register(
        code: string,
        miscode: string,
        firstname: string,
        lastname: string,
        email: string,
        password: string,
        invite: boolean = false
    ): Observable<any> {
        this.dataService.set('lastuser/email', email);
        return new Observable(observer => {
            this.apiService.register(
                code,
                miscode,
                firstname,
                lastname,
                email,
                password,
                invite
            )
            .subscribe(
                async res => {
                    await this.clearSession();

                    await this.setToken(res.token_type, res.access_token);

                    this.check()
                    .subscribe(res => {
                        observer.next(res);
                        observer.complete();
                    })
                },
                err => observer.error(err)
            );
        }).pipe(take(1));
    }

    login(email: string, password: string): Observable<any> {
        this.dataService.set('lastuser/email', email);
        return new Observable(observer => {
            this.apiService.login(
                email,
                password
            )
                .subscribe(
                    async res => {
                        await this.clearSession();

                        await this.setToken(res.token_type, res.access_token);

                        this.check()
                            .subscribe(res => {
                                observer.next(res);
                                observer.complete();
                            });
                    },
                    err => observer.error(err)
                );
        }).pipe(take(1));
    }

    assumeuser(token: string): Observable<any> {
        return new Observable(observer => {
            this.apiService.assumeuser(
                token
            )
                .subscribe(
                    async res => {
                        await this.setToken(res.token_type, res.access_token);

                        this.check()
                            .subscribe(res => {
                                observer.next(res);
                                observer.complete();
                            });
                    },
                    err => observer.error(err)
                );
        }).pipe(take(1));
    }

    resetpass(token: string, password: string): Observable<any> {
        return new Observable(observer => {
            this.apiService.resetpass(
                token,
                password
            )
            .subscribe(
                async res => {
                    await this.setToken(res.token_type, res.access_token);

                    this.check()
                    .subscribe(res => {
                        observer.next(res);
                        observer.complete();
                    });
                },
                err => observer.error(err)
            );
        }).pipe(take(1));
    }

    confirm(token: string): Observable<any> {
        return new Observable(observer => {
            this.apiService.confirm(token)
            .subscribe(
                async res => {
                    const user = await this.processMe(res);

                    observer.next(user);
                    observer.complete();
                },
                err => observer.error(err)
            );
        }).pipe(take(1));
    }

  // init() {
  //   const authToken = this.dataService.getAuthToken();
  //   if (authToken) {
  //     this.check().subscribe();
  //   } else {
  //     this.state.next(false);
  //   }
  // }

  // update(name, email, password = null, newPassword = null) {
  //   this.dataService.setAuthEmail(email);
  //   const update = new Observable(observer => {
  //     this.apiService.update(name, email, password, newPassword)
  //       .subscribe(
  //         data => {
  //           this.check()
  //             .subscribe(
  //               data => {
  //                 observer.next(data);
  //                 observer.complete();
  //               },
  //               error => observer.error(error)
  //             );
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return update;
  // }
  //
  // check(attempt: number = 0) {
  //   const check = new Observable(observer => {
  //     this.apiService.me()
  //       .subscribe(
  //         data => {
  //           this.user = data.user;
  //           this.company = data.company;
  //           this.dataService.setLanguage(data.user.language);
  //           this.dataService.setAuthEmail(data.user.email);
  //           this.state.next((new Date).getTime());
  //           this.last_authorisation = (new Date()).getTime();
  //
  //           observer.next(this.getAuth());
  //           observer.complete();
  //         },
  //         error => {
  //           if (!error.status) {
  //             if (! this.last_authorisation || this.last_authorisation <= (new Date()).getTime() - (60 * 60 * 24 * 14 * 1000)) {
  //               if (attempt > 2) {
  //                 this.dataService.setAuthToken(null);
  //                 this.user = null;
  //                 this.company = null;
  //                 this.state.next(false);
  //
  //                 observer.error(error);
  //                 return;
  //               }
  //
  //               this.errorService.show('error.cannot-reach', 'error.retry-connection', () => {
  //                 this.check(attempt + 1)
  //                   .subscribe(data => {
  //                     observer.next(data);
  //                     observer.complete();
  //                   }, err => {
  //                     observer.error(err);
  //                   });
  //               });
  //             } else {
  //               observer.next(this.getAuth());
  //               observer.complete();
  //             }
  //           }
  //
  //           if (error.status == 401 || attempt > 2) {
  //             this.dataService.setAuthToken(null);
  //             this.user = null;
  //             this.company = null;
  //             this.state.next(false);
  //
  //             observer.error(error);
  //             return;
  //           }
  //
  //           this.errorService.show('error.cannot-reach', 'error.retry-connection', () => {
  //             this.check(attempt + 1)
  //               .subscribe(data => {
  //                 observer.next(data);
  //                 observer.complete();
  //               }, err => {
  //                 observer.error(err);
  //               });
  //           });
  //         }
  //       );
  //   })
  //   .pipe(take(1));
  //   return check;
  // }
  //
  // confirm(token: string) {
  //   const confirm = new Observable(observer => {
  //     this.apiService.confirm(token)
  //       .subscribe(
  //         data => {
  //           observer.next(data);
  //           observer.complete();
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return confirm;
  // }
  //
  // login(email: string, password = null): Observable<any> {
  //   this.dataService.setAuthEmail(email);
  //   const login = new Observable(observer => {
  //     this.apiService.login(email, password)
  //       .subscribe(
  //         data => {
  //           if (! password) {
  //             observer.next(data);
  //             observer.complete();
  //             return;
  //           }
  //
  //           this.dataService.setAuthToken('Bearer ' + data.access_token);
  //
  //           this.check()
  //             .subscribe(
  //               data => {
  //                 observer.next(data);
  //                 observer.complete();
  //               },
  //               error => observer.error(error)
  //             );
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return login;
  // }
  //
  // forgotPassword(email: string): Observable<any> {
  //   this.dataService.setAuthEmail(email);
  //   const forgotpass = new Observable(observer => {
  //     this.apiService.forgotpass(email)
  //       .subscribe(
  //         data => {
  //           observer.next(data);
  //           observer.complete();
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return forgotpass;
  // }
  //
  // register(name: string, email: string, password: string, companyCode = null): Observable<any> {
  //   this.dataService.setAuthEmail(email);
  //   const register = new Observable(observer => {
  //     this.apiService.register(name, email, password, companyCode, this.dataService.getLanguage())
  //       .subscribe(
  //         data => {
  //           if (! data.access_token) {
  //             observer.next(data);
  //             observer.complete();
  //             return;
  //           }
  //
  //           this.dataService.setAuthToken('Bearer ' + data.access_token);
  //
  //           this.check()
  //             .subscribe(
  //               data => {
  //                 observer.next(data);
  //                 observer.complete();
  //               },
  //               error => observer.error(error)
  //             );
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return register;
  // }
  //
  // logout(): Observable<any> {
  //   const logout = new Observable(observer => {
  //     this.apiService.logout()
  //       .subscribe(
  //         data => {
  //           this.dataService.setAuthToken(null);
  //
  //           this.user = null;
  //           this.company = null;
  //           this.state.next(false);
  //
  //           observer.next(data);
  //           observer.complete();
  //         },
  //         error => observer.error(error)
  //       );
  //   })
  //   .pipe(take(1));
  //   return logout;
  // }
  //
  // isAuthenticated() {
  //   return this.state.value ? true : false;
  // }
  //
  // getAuth() {
  //   return {
  //     user: this.user,
  //     company: this.company,
  //   };
  // }
  //
  // getToken() {
  //   return this.dataService.getAuthToken();
  // }
  //
  // getEmail() {
  //   return this.dataService.getAuthEmail();
  // }
  //
  // getName() {
  //   return this.dataService.getAuthName();
  // }
}
